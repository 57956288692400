// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

// IMPORT BARBA
import barba from '@barba/core';
import barbaCss from '@barba/css';

// IMPORT OWL CAROUSEL
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

// IMPORT TYPEMATE
import TypeMate from 'typemate';

// On page load do
document.addEventListener("turbolinks:load", () => {

  //barba page transitions
  barba.use(barbaCss)

  barba.init({
    debug: true,

    //Prevent barba from loading sign_out, as it logs you out if you hover link without it
    prefetchIgnore: '/users/sign_out',

  	transitions: [
  		{
  			name: "fade",

        leave() {
          console.log('out')
        },

        enter() {
          console.log('in')
        },

  			beforeEnter() {

  				// reset page positioning
  				window.scrollTo({
  					top: 0,
  					behavior: "auto"
  				});

          // Remove orphans
          typeMate()

  			},

  			beforeLeave({ current, next, trigger }) {

  				// header links change text
  				const headerLinks = document.querySelectorAll("header a")
  				const href = next.url.path

  				// read link of page and find it in the header
  				headerLinks.forEach(link => {
  					if (link.getAttribute("href") === href) {
  						link.classList.add("bold")
  					} else {
  						link.classList.remove("bold")
  					}
  				})

          // Transition document background to white
          $('body').css('backgroundColor', 'white')

  			},

        after() {
          // Loading screen, wait for content to load
          removeLoader()

          // Look for triggers to load
          $('.triggerLoader').click(function() {
            $('.loader').css('opacity', '0')
            $('.loader').css('display', 'grid')
            setTimeout(function(){
              $('.loader').css('opacity', '1')
            }, 5);
          })

          // Return document background colour to black
          $('body').css('backgroundColor', 'black')

        }

  		}
  	],

    views: [
      {
        namespace: "home",

        afterEnter() {

          // Load in the program
          loadProgram()

          // Load in Owl Carousel
          loadCarousel()

          // Load in announcements
          loadAnnouncements()

        }

      },

      {
        namespace: "program",

        afterEnter() {

          // Load in the program
          loadProgram()

        }
      },

      {
        namespace: "announcements",

        afterEnter() {

          // Load in announcements
          loadAnnouncements()
          // loadVideos()

        }
      },

      {
        namespace: "faqs",

        afterEnter() {

          // Load in announcements
          loadAnnouncements()

        }
      },

      {
        namespace: "about",

        afterEnter() {

          // Load in Owl Carousel
          loadCarousel()

          $('.principle').click(function(){
            $(this).find('.body').slideToggle()
            $(this).find('.title span').toggle()
          })

        }
      }

    ]
  })

  // Remove flash message
  // $('.ribbon').delay('3000').slideUp()
  $('.ribbon svg').click(function() {
    $('.ribbon').css('display', 'none')
  })

  // Mobile Menu Show
  var mobileHeaderVisible = false
  $('#mobileMenu').click(mobileHeaderToggle)
  $('header a').click(mobileHeaderToggle)

  function mobileHeaderToggle() {
    console.log('test')
    if (window.innerWidth < 1100) {
      if (mobileHeaderVisible == false) {
        $('header .headerWrapper').css('transform', 'translateX(0vw)')
        $('.bi').css('transform', 'rotate(180deg)')
        mobileHeaderVisible = true
      } else {
        $('header .headerWrapper').css('transform', 'translateX(100vw)')
        $('.bi').css('transform', 'rotate(0deg)')
        mobileHeaderVisible = false
      }
    }
  }

  // Accessibility variables
  var keyListener = false

  // Owl carousel
  loadCarousel()
  function loadCarousel() {
    $(document).ready(function(){

      $('.owl-carousel.events').owlCarousel({
        loop: true,
        center: false,
        margin: 0,
        nav: true,
        dots: true,
        autoplay: false,
        autoplayHoverPause: true,
        autoplayTimeout: 3000,
        items: 1
      });

      $('.owl-carousel.sponsors').owlCarousel({
        loop: true,
        center: false,
        margin: 0,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 3000,
        autoWidth: true
      });

      console.log('Load Carousel')
      // removeLoader()
    })
  }

  // Loader on link click
  $('header a').click(function() {
    $('.loader').css('opacity', '0')
    $('.loader').css('display', 'grid')
    setTimeout(function(){
      $('.loader').css('opacity', '1')
    }, 5);
  })

  // Loading sequence
  window.addEventListener('load', function(){
    removeLoader()
  })
  function removeLoader() {
    console.log("Timing out")
    $('.loader').css('opacity', '0')
    setTimeout(function(){
      $('.loader').css('display', 'none')
      $('.loader').css('opacity', '1')
    }, 500);
  }

  // header links change text
  const headerLinks = document.querySelectorAll("header a")
  const href =  window.location.pathname
  // read link of page and find it in the header
  headerLinks.forEach(link => {
    console.log(href, link.getAttribute("href"))
    if (link.getAttribute("href") === href) {
      link.classList.add("bold")
    } else {
      link.classList.remove("bold")
    }
  })

  // Get rid of text orphans
  typeMate()
  function typeMate() {
    const parent = document.querySelector('main');
    const typeMateInstance = new TypeMate(parent, { selector: 'h2, h3, p' });
    // Run it
    typeMateInstance.apply();
  }

  // To update the current time in the header
  // updateTime()
  // function updateTime() {
  //   var dateInfo = new Date();
  //   /* time */
  //   var hr, _min = (dateInfo.getMinutes() < 10) ? "0" + dateInfo.getMinutes() : dateInfo.getMinutes(), ampm = (dateInfo.getHours() >= 12) ? "PM" : "AM";
  //   // replace 0 with 12 at midnight, subtract 12 from hour if 13‐23
  //   if (dateInfo.getHours() == 0) {
  //      hr = 12;
  //   } else if (dateInfo.getHours() > 12) {
  //      hr = dateInfo.getHours() - 12;
  //   } else {
  //      hr = dateInfo.getHours();
  //   }
  //   var currentTime = hr + ":" + _min + ampm.toLowerCase();
  //   // print time
  //   document.getElementById("currentTime").innerHTML = currentTime;
  //   // update every
  //   var time = setTimeout(updateTime,5000);
  // }


  // loadAnnouncements()
  function loadAnnouncements() {
    // Load in announcements, default to show the first one
    $('.announcementShow').each(function(index) {
      if (index == 0) {
        $(this).removeClass('hide')
      } else {
        $(this).addClass('hide')
      }
    })
    // Show the selected announcement
    $('.announcement').click(function() {
      var selectedAnnouncement = $(this).find('h3').html()
      $('.announcementShow').each(function() {
        if ($(this).find('h2').html() == selectedAnnouncement) {
          $(this).removeClass('hide')
        } else {
          $(this).addClass('hide')
        }
      })
    })
    loadVideos()
    addImages()
    // removeLoader()

  }

  // Insert Images from database
  function addImages() {
    // Setup the bucket into an array
    var imageBucket = new Array

    // Go through each image in the bucket
    $('.imageBucket img').each(function(index) {
      // Grab the source file path and place it into an array
      imageBucket[index] = $(this).attr('src')
    })

    console.log(imageBucket)

    // Grab the image tags from the body copy
    $('.announcementShow .content img').each(function(index) {
      // Assign the src file path
      $(this).attr('src', imageBucket[index])
      $(this).attr('id', index)
      $(this).wrap("<div class='photo'></div>")
    })

    console.log('Images Added')
  }

  // Load Videos on page and LazyLoad
  function loadVideos() {
    // Go through and add video source
    $('.video').each(function(index){
      var videoLink = $(this).html()
      // var videoThumbnail = $(this).find('img').src
      $(this).replaceWith("<div id='" + "video" + index + "' class='videoWrapper' data-yt-url='" + videoLink +"'><img async class='play-youtube-video'></div>")
    })
    // Youtube video lazy load
    $(document).ready(function() {
      // select all video wrappers on page
      var video_wrapper = $('.videoWrapper');
      // if a video wrapper exists then
      if(video_wrapper.length) {
        // on click inject the youtube link data from the HTML
        $('.videoWrapper').on('click', function(){
          $('#'+this.id).html('<iframe allowfullscreen frameborder="0" class="embed-responsive-item" src="' + $(this).data("yt-url") + '"></iframe>');
          // add margin top and video sizing rules
          $(this).addClass('iframe-container')
        });
      }
    })
    console.log('Videos Added')
  }



  // Load program master function
  // loadProgram()
  function loadProgram() {
    console.log('Load Program')

    // Join events with same start and end time
    var events = document.querySelectorAll('.event')
    var eventsFlexibleJoin = document.querySelectorAll('.event .flexibleJoin')
    var eventShowCounter = 0
    var falseCounter = 0
    var trueCounter = 0

    // Listen for click open overview
    var overviewOpen = false
    $('#openOverview').click(function(){
      $('#overviewImage').slideToggle()
      if (overviewOpen == false) {
        $(this).css('transform', 'rotate(45deg)')
        overviewOpen = true
      } else {
        $(this).css('transform', 'rotate(0deg)')
        overviewOpen = false
      }
    })

    // Make all events joinable
    $('.event').each(function(index){
      $(this).addClass('joinable')
    })

    // Search through each event
    joinEvents()
    function joinEvents() {
      console.log('Join Events')
      eventShowCounter = 0
      falseCounter = 0
      trueCounter = 0
      events = document.querySelectorAll('.event.joinable')
      eventsFlexibleJoin = document.querySelectorAll('.event.joinable .flexibleJoin')

      $(events).each(function(index){
        if ($(this).css('display') === 'flex') {
          // Compare this with the one before
          if ($(this).find('.startTime').html() == $(events[index - 1]).find('.startTime').html() && $(this).find('.endTime').html() == $(events[index - 1]).find('.endTime').html() && $(events[index - 1]).hasClass('joinable') == true && $(this).find('.dateTime').html() == $(events[index - 1]).find('.dateTime').html()) {
            // Add a joining class to this and the one before
            $(this).addClass('join')
            $(events[index - 1]).addClass('join')
            // Move details to parent event
            var eventDetails = $(this).find('.details')
            $(eventsFlexibleJoin[falseCounter - 1]).append(eventDetails.clone().addClass('joined'))
            // Hide original event box
            $(this).css('display', 'none')
            trueCounter = trueCounter + 1
          } else {
            falseCounter = falseCounter + 1 + trueCounter
            trueCounter = 0
          }
          // Apply alternating background colours
          if ($(this).css('display') == 'flex') {
            eventShowCounter = eventShowCounter + 1
            $(this).css('background-color', 'white')
            if (eventShowCounter % 2 == 0) {
              $(this).css('background-color', '#F2F2F2')
            }
          }
        }
      })

      // Show Author on hover
      $('.event .details').hover(function() {
        $(this).find('.authors').slideDown(120)
      }, function() {
        if (authorsOpen[$('.event .details').index(this)] != true) {
          $(this).find('.authors').slideUp(120)
        }
      })
    }

    // Split events for searching and filtering
    function splitEvents() {
      console.log('Split Events')
      // Remove events that have been joined
      $('.details').each(function(index){
        if ($(this).hasClass('joined') == true) {
          $(this).remove()
        }
      })
      // Show all original events, change colours
      $('.event').each(function(index){
        $(this).removeClass('join')
        $(this).removeClass('joinable')
        $(this).css('display', 'flex')
        // Apply alternating background colours
        if ($(this).css('display') == 'flex') {
          eventShowCounter = eventShowCounter + 1
          $(this).css('background-color', 'white')
          if (eventShowCounter % 2 == 0) {
            $(this).css('background-color', '#F2F2F2')
          }
        }
      })
    }

    // Hover colour
    var currentColour
    $('.event').hover(function() {
      currentColour = $(this).css('background-color')
      $(this).css('background-color', 'rgba(255, 220, 20, 0.4)')
    }, function() {
      $(this).css('background-color', currentColour)
    })

    // Event Type filters
    var selectedEventType
    $('.eventTypes a').click(function(){
      splitEvents()
      // Select the text
      $('.eventTypes a').each(function(){
        $(this).removeClass('selected')
      })
      $(this).addClass('selected')
      // Reset dropdowns
      $('.dropdown').each(function(){
        $(this).find('.title').html('Filter: All')
      })
      // Get class of selected filter
      selectedEventType = $(this).attr('id')
      // Compare to each event
      $('.events .event').each(function() {
        $(this).css('display', 'none')
        if ($(this).hasClass(selectedEventType) == true) {
          $(this).css('display', 'flex')
          $(this).addClass('joinable')
        }
      })
      joinEvents()
      listenClickJoined()
    })

    // Dropdowns
    var dropDownOpen = false
    $('.dropdown').click(function(){
      if (dropDownOpen == false) {
        openDropDown($(this))
      } else {
        closeDropDowns($(this))
      }
    })

    function openDropDown(thisObj) {
      $(thisObj).css('border-radius', '3px 3px 0px 0px')
      $(thisObj).find('.options').css('display', 'block')
      $(thisObj).find('span.right').html('&uarr;')
      dropDownOpen = true
    }

    function closeDropDowns(thisObj) {
      $('.dropdown').each(function(){
        $(thisObj).css('border-radius', '3px')
        $(thisObj).find('.options').css('display', 'none')
        $(thisObj).find('span.right').html('&darr;')
      })
      dropDownOpen = false
    }

    // Select dropdown option
    var selectedFilter
    $('.dropdown .options .option').click(function(){
      splitEvents()
      // Get class of selected filter
      selectedFilter = $(this).attr('id')
      var filterText = $(this).html()
      // Set text to filter
      $('.dropdown').each(function(){
        $(this).find('.title').html('Filter: All')
      })
      $(this).parents('.dropdown').find('.title').html('Filter: ' + filterText)
      // Reset types
      $('.eventTypes a').each(function(){
        $(this).removeClass('selected')
        $('.eventTypes #all').addClass('selected')
      })
      // Compare to each event
      $('.events .event').each(function() {
        $(this).css('display', 'none')
        if ($(this).hasClass(selectedFilter) == true) {
          $(this).css('display', 'flex')
          $(this).addClass('joinable')
        }
      })
      joinEvents()
      listenClickJoined()
    })

    // SEARCH BAR
    const searchInput = document.forms['searchInput'].querySelector('input');
    var list = document.querySelectorAll('.event')
    const allEvents = document.querySelectorAll('.event')
    var i = 0;

    searchInput.addEventListener('keyup', function(e){
      // Reset types
      $('.eventTypes a').each(function(){
        $(this).removeClass('selected')
        $('.eventTypes #all').addClass('selected')
      })
      // Reset dropdowns
      $('.dropdown').each(function(){
        $(this).find('.title').html('Filter: All')
      })
    	const term = e.target.value.toLowerCase();
      splitEvents()
    	for (i = 0; i < list.length; i++) {
    		var title = list[i].textContent
    		if (title.toLowerCase().indexOf(term) != -1) {
    			allEvents[i].style.display = 'flex'
          $(allEvents[i]).addClass('joinable')
    		}
    		else {
    			allEvents[i].style.display = 'none'
    		}
    	}
      joinEvents()
      listenClickJoined()
    })






    // Slide open preview
    listenClick()
    var authorsOpen = []
    function listenClick() {
      $('.details').click(function() {
        console.log($(this))
        // Find index of element selected
        var authorsIndex = $('.events .details').index(this)
        // Set all to not open
        $('.details').each(function(index) {
          authorsOpen[index] = false
        })
        // Set selected to open
        authorsOpen[authorsIndex] = true
        // If open, don't slide up, slide all up
        $('.details').each(function(index) {
          if (authorsOpen[$('.event .details').index(this)] != true) {
            $(this).find('.authors').slideUp(120)
            $(this).find('.info').slideUp()
          }
        })
        $(this).find('.info').slideToggle()
        $(this).find('.authors').slideDown()
      })
    }

    function listenClickJoined() {
      $('.details.joined').click(function() {
        console.log($(this))
        // Find index of element selected
        var authorsIndex = $('.events .details').index(this)
        // Set all to not open
        $('.details').each(function(index) {
          authorsOpen[index] = false
        })
        // Set selected to open
        authorsOpen[authorsIndex] = true
        // If open, don't slide up, slide all up
        $('.details').each(function(index) {
          if (authorsOpen[$('.event .details').index(this)] != true) {
            $(this).find('.authors').slideUp(120)
            $(this).find('.info').slideUp()
          }
        })
        $(this).find('.info').slideToggle()
        $(this).find('.authors').slideDown()
      })
    }

    // Allow enter key to open window for accessibility
    if (keyListener == false) {
      $(document).on('keypress',function(e) {
        if(e.which == 13) {
          keyListener = true
          // Find index of element selected
          var authorsIndex = $('.events .details').index('.details:focus')
          // Set all to not open
          $('.details').each(function(index) {
            authorsOpen[index] = false
          })
          // Set selected to open
          authorsOpen[authorsIndex] = true
          // If open, don't slide up, slide all up
          $('.details').each(function(index) {
            if (authorsOpen[$('.event .details').index('.details:focus')] != true) {
              $('.details:focus').find('.authors').slideUp(120)
              $('.details:focus').find('.info').slideUp()
            }
          })
          $('.details:focus').find('.info').slideToggle()
          $('.details:focus').find('.authors').slideDown()
        }
      });
    }

    removeLoader()
  }


})
